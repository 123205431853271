import '../styles/resume.css'
import resume from '../media/Resume-Tyler-Wingood.pdf'

const Resume = (props) => {
    return ( 
        <>
        <div className="resume-container">
            <h5 className="resume-text">Interested in learning more about me?</h5>
            <div className="resume">
                <a href={resume} download="resume" className='resume-link'>Download Resume</a>
            </div>  
        </div>
        </>
     );
}
 
export default Resume;