import { Container, Row, Col, Button, Form, FormGroup } from 'react-bootstrap'
import { Input } from 'reactstrap'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/app.css'
import '../styles/contact.css'

const Contact = (props) => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_xtfl8fd', 'template_cklsks7', form.current, 'w9BJugxml5dNDvudG')
          .then((result) => {
              console.log(result.text);
              alert("Message Sent")
              form.current.reset()
          }, (error) => {
              console.log(error.text);
          });
      };

    return ( 
        <div className="component-container contact" id='contact'>
        <Container className="inner-container">
            <Row className="row">
                <Col className="skills-column">
                    <h3>Contact me</h3>
                </Col>
            </Row>
            <Row className="row">
                <Col className="contact-column">
                    <Form id='contact-form'ref={form} onSubmit={sendEmail}>
                        <FormGroup className='input-box'>
                            <Input type="text" name="uname" className='uname' placeholder="Your Name"></Input>
                        </FormGroup>
                        <FormGroup className='input-box'>
                            <Input type="email" name="email" className='uemail'placeholder="Your Email"></Input>
                        </FormGroup>
                        {/* Text Area allows for multiple line inputs, compared to "text" */}
                        <FormGroup className='input-box'>
                            <Input type="textarea" name="message" className='umessage' placeholder="Your Message"></Input>
                        </FormGroup>
                        <Row className="row">
                            <Col className="column skills-column">
                                <FormGroup>
                                    <Button type="submit" id='contact-button'>Send Message</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    </div>
    );
}
 
export default Contact;