import { Container, Row, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { Anchor } from 'antd'
import "../styles/navbar.css"
import { useEffect } from 'react';

const { Link } = Anchor

const Navbar = (props) => {  
    
    // Header Transition
    useEffect(() => {
        const navBarOuterContainer = document.querySelectorAll(".navbar-outer-container")
        function transformHeader(){
            navBarOuterContainer.forEach(container=>{
                if (window.scrollY > 650){
                    container.classList.add("transform-active")
                } else {
                    container.classList.add("transform-clear")
                    container.classList.remove("transform-active")
                }
            })
        }
        window.addEventListener("scroll", transformHeader)
        
    }, []);
    
    return ( 
    <div className="navbar-outer-container">
        <Container className='inner-container navbar-inner-container'>
            <Row className='row navbar-upper-row'>
                <Col className='column navbar-upper-right-column'>
                    <ul id='navbar-list'>
                        {/* <li><a href="#page-top" className="navlink navbar-navlink" id='navbar-firstchild'>Tyler Wingood</a></li> */}
                        <li>
                            <a href="#about" title="About" className="navlink navbar-navlink">About</a>
                        </li>
                        <li>
                            <a href="#skills" title="Skills" className="navlink navbar-navlink">Skills</a>
                        </li>
                        <li>
                            <a href="#portfolio" title="Portfolio" className="navlink navbar-navlink">Portfolio</a>
                        </li>
                        <li>
                            <a href="#experience" title="Experience" className="navlink navbar-navlink">Experience</a> 
                        </li>
                        <li>
                            <a href="#contact" title="Contact Me" className="navlink navbar-navlink">Contact</a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>    
    </div>
    );
}
 
export default Navbar;