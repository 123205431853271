import "../styles/app.css"
import "../styles/about.css"
import { Container, Row, Col } from "react-bootstrap";

const About = (props) => {
    return ( 
    <div className="component-container about" id="about">
        <Container className="inner-container about-inner-container">
            <Row className="row1">
                <Col className="column skills-column" id="about-me">
                    <h3>About Me</h3>
                </Col>
            </Row>
            <Row className="row2">
                <Col className="column about-columns">
                <div className="about-paragraph about-paragraph-left">Hello and welcome to my portfolio! My name is Tyler Wingood, and I am a software developer who is focusing on a career within the Salesforce Ecosystem. I am passionate about continous learning and engagement with my profession, hobbies, and peers. Check out my <span><a href="https://www.linkedin.com/in/tyler-wingood/">LinkedIn</a></span>, <span><a href="https://github.com/T-Wingood">Github</a></span>, and <span><a href="https://trailblazer.me/id/twingood">Trailhead!</a></span></div>
                <div className="about-paragraph about-paragraph-middle">My professional career started in 2011 as a licensed Pharmacist. I practiced for 11 years until deciding that I wanted to make a change. Leaving pharmacy for technology opened a world for me where I am so much happier and fullfilled.</div>
                <div className="about-paragraph about-paragraph-right">My role as a Salesforce Admin is to provide value to my team, learning as much as I can, and always bringing solutions to barriers. Ultimately I would like to become a Salesforce Architect, and to get there I will holistically understand the ecosystem from a Admin, Developer, and Business Analyst standpoint.
                 </div>
                </Col>
            </Row>
        </Container>
    </div>
    );
}
 
export default About;