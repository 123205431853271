import About from './About';
import Skills from './Skills'
import Portfolio from './Portfolio'
import Experience from './Experience'
import Contact from './Contact'
import Resume from './Resume'
import "../styles/homepage.css"

const HomePage = (props) => {
    return ( 
        <>
            <About/>
            <Skills/>
            <Portfolio/>
            <Resume/>
            <Experience/>
            <Contact/>
        </>
    );
}
 
export default HomePage;