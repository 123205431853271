import './styles/app.css'
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import Footer from './components/Footer'
import Typical from 'react-typical'

function App() {

  return (
    <>
    <div className='outer-container' id='page-top'>
      <Navbar className="navbar"/>
      <div id="hero-image">
        <div className="hero-text">
            <h1>Tyler Wingood</h1>
            <h2>Software Developer and...
              <Typical
              loop={Infinity}
              wrapper="h2"
              steps={[
                'Salesforce Ranger', 
                2000,
                'Salesforce Admin', 
                2000,
                'Pharmacist',
                2000,
                'Ironman Athlete',
                2000,
                'Explorer',
                2000,
                'Husband',
                2000,
                'Forever Learner',
                2000
              ]}
              />
            </h2>
        </div>
      </div>
      <Routes>
        <Route path='/' element = {<HomePage/>}/>
        <Route path='/Homepage' element = {<HomePage/>}/>
      </Routes>
      <Footer className="footer"/>
    </div>
    </>
  );
}

export default App;
