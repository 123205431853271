import { Container, Col, Row } from "react-bootstrap";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai"
import { FaSalesforce } from "react-icons/fa"
import { NavLink } from "react-router-dom";
import { Anchor } from 'antd'
import '../styles/footer.css'

const { Link } = Anchor

const Footer = (props) => {
        return ( 
        <div className="footer-outer-container">
        <Container className='inner-container' id="inner-container">
            <Row>
                <Col className='column footer-upper-column'>
                    <a href="https://www.linkedin.com/in/tyler-wingood/"><AiFillLinkedin className="icon"/></a>
                    <a href="https://github.com/T-Wingood"><AiFillGithub className="icon"/></a>
                    <a href="https://trailblazer.me/id/twingood"><FaSalesforce className="icon"/></a>
                </Col>
            </Row>
            <Row>
                <Col className="column footer-lower-column">
                    <a href="#page-top" title="© Tyler Wingood 2022" id="pagetoplink">© Tyler Wingood 2022</a>
                </Col>
            </Row>
        </Container>    
    </div>
    );
}
 
export default Footer;