import { Container, Row, Col, Button } from "react-bootstrap";
// import '../styles/app.css'
import '../styles/portfolio.css'

const Portfolio = (props) => {
    return ( 
        <div className="component-container" id="portfolio">
            <Container>
                <Row id="portfolio-header">My Portfolio</Row>
                {/* <Row>
                    <Col id="portfolio-buttons">
                        <Button className="portfolio-button">All</Button>
                        <Button className="portfolio-button">Salesforce</Button>
                        <Button className="portfolio-button">Javascript</Button>
                    </Col>
                </Row> */}
                <Row className="port-dis-row">
                    <Col className="port-dis-col">
                        <div className="port-dis-box" id="guess-the-number">
                            <a href="https://github.com/T-Wingood/TWing-GTN">
                                <div className="port-details">
                                    <div className="port-header">
                                    Guess the Number
                                    </div>
                                    <br/>
                                    <div className="port-description">
                                    Console based game that uses logic sequences to guess a randomized number.
                                    </div>
                                    <br/>
                                    <div className="port-skills">Skills Used:
                                    I/O's, Promises, Functions, Javascript</div>
                                </div>
                            </a>
                            </div>
                        <div className="port-dis-box" id="zorkington">
                            <div className="port-details">
                                <a href="https://github.com/T-Wingood/TWing-Zorkington">
                                    <div className="port-header">
                                    Zorkington
                                    </div>
                                    <br/>
                                    <div className="port-description">
                                    Console based adeventure game that requires the user to reach an end goal
                                    </div>
                                    <br/>
                                    <div className="port-skills">Skills Used:
                                    State transitions, Object methods, Object patterns, utilization of functions, methods, and objects, Javascript</div>
                                </a>
                            </div>
                        </div>
                        <div className="port-dis-box" id="jeopardy">
                            <div className="port-details">
                                <a href="https://github.com/T-Wingood/TWing-JeopardyBoard">
                                    <div className="port-header">
                                    Jeopardy
                                    </div>
                                    <br/>
                                    <div className="port-description">
                                    Triva game based on the popular TV show
                                    </div>
                                    <br/>
                                    <div className="port-skills">Skills Used:
                                    DOM, Responsive Layouts, Embedding Media, URLs, Fetching, Javascript, HTML, CSS, Git</div>
                                </a>
                            </div>
                        </div>
                        <div className="port-dis-box" id="react-portfolio">
                            <div className="port-details">
                                <a href="https://github.com/T-Wingood/TWing-React-Portfolio">
                                    <div className="port-header">
                                    React Portfolio
                                    </div>
                                    <br/>
                                    <div className="port-description">
                                    (My first ever portfolio... its nice to see how far its came) A starting Portfolio layout to showcase projects and skills
                                    </div>
                                    <br/>
                                    <div className="port-skills">Skills Used:
                                    Events, Props, States, React, Javascript, CSS, Git</div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="port-dis-row" id="port-dis-bottom-row">
                    <Col className="port-dis-col">
                        <div className="port-dis-box" id="yelpington">
                            <div className="port-details">
                                <a href="https://github.com/T-Wingood/TWing-Yelpington">
                                    <div className="port-header">
                                    Yelpington
                                    </div>
                                    <br/>
                                    <div className="port-description">
                                    An online directory of local resturaunts using APIs, forms, and geographic map integration.
                                    </div>
                                    <br/>
                                    <div className="port-skills">Skills Used:
                                    React, Javascript, CSS, Express.js, Leaflet, Git</div>
                                </a>
                            </div>
                        </div>
                        <div className="port-dis-box" id="flip-profile">
                            <div className="port-details">
                                <a href="https://comforting-sable-4cd144.netlify.app/">
                                    <div className="port-header">
                                    Flip Profile
                                    </div>
                                    <br/>
                                    <div className="port-description">
                                    Simple App that allows user to showcase different QR codes upon clicking the screen.
                                    </div>
                                    <br/>
                                    <div className="port-skills">Skills Used:
                                    HTML, CSS, Javascript</div>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
     );
}
 
export default Portfolio;