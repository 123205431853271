import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import "../styles/skills.css"
import "../styles/app.css"
import { FaReact, FaSalesforce } from "react-icons/fa";
import { AiFillHtml5, AiFillGithub } from "react-icons/ai"
import { SiJavascript, SiMongodb, SiTrello } from "react-icons/si"
import Typical from 'react-typical'

const Skills = (props) => {

    const [react, setReact] = useState("")
    function reactDescript(e){
        if (e === "react"){
            setReact("The react library was the focus of my inital learning and break through into tech. I utilzie this framework to create elegent single-page applications, such as the portfolio you are viewing right now.")
        }
        if (e === "html"){
            setReact("I utilize HTML and CSS to build web applications and enhance the user experience.")
        }
        if (e === "java-script"){
            setReact("Making clean, reusable, and functional code is fundamental to my coding values.")
        }
        if (e === "mongodb"){
            setReact("Utilizing MongoDB to store what is important to the user, and using my custom database schemas.")
        }
        if (e === "agile"){
            setReact("Contribution, Teamwork, Efficiency, Flexibility, Accountability. Whether working with team members or by myself, I remain flexible throughout ambiguity.")
        }
        if (e === "git"){
            setReact("Working with teams within this repository, ensuring good 'Git workflow etiquette' is important to me.")
        }
        if (e === "salesforce"){
            setReact("Currently a Ranger on trailhead, with a administration certification antcipated in December 2022. I absoutely love Salesforce. Flows, permission sets, record types, automated fields, restriction rules, heirarchial roles, highly customizable and much more. The solutions and functionality that Salesforce provides pushes me to continuously learn what else it is capable of.")
            
        }
        
    }

    useEffect(() => {
        reactDescript("salesforce")
    }, []);

    return ( 
        <div className="component-container skills" id="skills">
            <Container className="inner-container">
                <Row>
                    <Col className="skills-column" id="skills-header">
                        <h3>My Skillset</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className = "skills-column">
                        <FaSalesforce className="skills-tag" onClick={()=>{reactDescript("salesforce")}}/>
                        <FaReact className="skills-tag" onClick={()=>{reactDescript("react")}}/>
                        <AiFillHtml5 className="skills-tag" onClick={()=>{reactDescript("html")}}/>
                        <SiJavascript className="skills-tag" onClick={()=>{reactDescript("java-script")}}/>
                        <SiMongodb className="skills-tag" onClick={()=>{reactDescript("mongodb")}}/>
                        <SiTrello className="skills-tag" onClick={()=>{reactDescript("agile")}}/>
                        <AiFillGithub className="skills-tag" onClick={()=>{reactDescript("git")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="skills-column">
                        <div className="skills-description">
                        {react}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
     );
}
 
export default Skills;