import '../styles/app.css'
import '../styles/experience.css'
import { Container, Row, Col } from "react-bootstrap";

const Experience = (props) => {
    return ( 
        <div className=" component-container" id='experience'>
        <Container className="inner-container">
            <Row className="row">
                <Col className="column" id='experience-header'>
                    <h3>My Experience</h3>
                </Col>
            </Row>
            <Row className="exp-row">
                <Col className="experience-column left-column">
                    <h4>2022</h4>
                    <h5>Full Stack Intern - BRIA</h5>
                    <ol>
                        <li>
                        Used Object-Oriented Javascript library such as React.Js to develop a front-end user interface. 
                        </li>
                        <li>
                        Produced dynamic and browser compatible pages using HTML, CSS, and Javascript.
                        </li>
                        <li>
                        Designed and implemented a backend framework using Express.js
                        </li>
                        <li>
                        Collaborated with team-members to design, propose, and implement product owner approved wireframes. 
                        </li>
                        <li>
                        Worked in an Agile, collaborative environment to receive design requirements, peer program, and test applications. 
                        </li>
                    </ol>
                </Col>
                <Col className = "experience-column right-column">
                    <h4>2022</h4>
                    <h5>Medical Chart Reviewer / Pharmacist Consultant - UVM / Aspen Rx</h5>
                    <ol>
                        <li>
                        Reviews medical files to evaluate the delivery of health care services for patient populations and geographic location. 
                        </li>
                        <li>
                        Travel to medical facilities or provider offices to complete review services 
                        </li>
                        <li>
                        Perform reviews in a timely and concise manner along with documented rationale.
                        </li>
                        <li>
                        Interact with health professionals in a professional manner. 
                        </li>
                        <li>
                        Perform patient outreach on behalf of patient health plans. 
                        </li>
                    </ol>
                </Col>
            </Row>
            <Row className = "exp-row">
                <Col className="experience-column left-column">
                    <h4>2017-2022</h4>
                    <h5>Senior Pharmacy Consultant - Edge Pharma, LLC</h5>
                    <ol>
                    <li>
                    Led the initiative to implement the Batchmaster ERP system, a 12+ Month long process to completely integrate the company’s production, logistical, and financial departments into one system. 
                    </li>
                    <li>
                    Implemented and validated the Rotronics Environmental Monitoring system consisting of over 200 monitoring probes with various parameters including temperature, humidity, pressure, alarm, and email/text functionality.  
                    </li>
                    <li>
                    Successfully converted a paper based continuous education system to the electronic Talent LMS system, streamlining onboarding and education requirements for the business. 
                    </li>
                    <li>
                    Established and maintained regulatory compliance through writing, reviewing, and approving standard operating procedures (SOPs), forms, and work instructions based on regulations and guidelines such as CFR-21 part 210/211, USP, and WHO. 
                    </li>
                    <li>
                    Worked cross functionally by performing weekly upper and midlevel management meetings using Microsoft (MSFT) PowerPoint and Teams.  
                    </li>
                    </ol>
                </Col>
                <Col className = "experience-column right-column">
                    <h4>2013-2017</h4>
                    <h5>Pharmacist in Charge - Edge Pharmacy Services, LLC</h5>
                    <ol>
                        <li>Managed and performed facility maintenance, customer service, and quality assurance activities. </li>
                        <li>Hired new employees, developed departments, and assisted with managing company growth.</li>
                        <li>Assisted with tasks necessary for establishing a new business, such as developing procedures, reaching out to clients, and working in financial or administrative roles.</li>
                        <li>Performed compounding activities for hospitals, surgery centers, and physician offices</li>
                        <li>Ensured that all state and federal requirements were met</li>
                    </ol>
                </Col>
            </Row>
        </Container>
    </div>
    );
}
 
export default Experience;